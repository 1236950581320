import React, {Component} from 'react';
import './Codewars.css';

class Codewars extends Component {
    render() {
        return (
        	<div className={'Codewars'}>
				<a href="https://www.codewars.com/users/MaximKofanov" target="_blank">
					<img src="https://www.codewars.com/users/MaximKofanov/badges/large" alt="codewars" />
				</a>
        	</div>
        );
    }
}

export default Codewars;